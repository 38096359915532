import React, { Component } from "react"
import { Location } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/Main/Main"

export default class Publish extends Component {
  render() {
    return (
      <Location>
        {props => (
          <Layout {...props}>
            <SEO title="Publish" />
            <Main {...props} />
          </Layout>
        )}
      </Location>
    )
  }
}
